<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationForm"
            title="Basic Information"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Resource Type"
                    label-for="resourceType"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Resource Type"
                      rules="required"
                    >
                      <v-select
                        id="resourceType"
                        v-model="resourceType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['Shared Resource', 'Individual Resource', 'Limited Resource']"
                        label="Resource Type"
                        placeholder="Resource Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Resource Name"
                    label-for="resourceName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Resource Name"
                      rules="required"
                    >
                      <b-form-input
                        id="resourceName"
                        v-model="resourceName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Meeting Room, Internet Credits, AWS Credits etc."
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Description"
                    label-for="description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Description"
                      rules="required"
                    >
                      <b-form-textarea
                        id="description"
                        v-model="resourceDesc"
                        placeholder="Write max 50 word description"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Upload Images"
                    label-for="images"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Images"
                      rules="required"
                    >
                      <b-form-file
                        id="images"
                        v-model="resourceImages"
                        accept="image/jpeg, image/png, image/gif"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Resource Group tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Resource Group"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Resource Group
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Add or Select Group"
                    label-for="resourceGroup"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Images"
                      rules="required"
                    >
                      <v-select
                        id="resourceGroup"
                        v-model="resourceGroup"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="Resource Group"
                        placeholder="Select from existing groups or enter new group name"
                        taggable
                        push-tags
                        :options="['Meeting Room','Conference Hall','Auditorium']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Booking Info tab  -->
          <tab-content
            :before-change="validationFormAddress"
            title="Booking Information"
          >
            <validation-observer
              ref="addressRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Booking Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Resource Type"
                    label-for="resourceType"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Resource Type"
                      rules="required"
                    >
                      <v-select
                        id="resourceType"
                        v-model="resourceType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['Shared Resource', 'Individual Resource', 'Limited Resource']"
                        label="Resource Type"
                        placeholder="Resource Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="resourceType == 'Shared Resource' ? 'Slot Duration' : 'Unit'
                    "
                    label-for="billingUnit"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="resourceType == 'Shared Resource' ? 'Slot Duration' : 'Unit'"
                      rules="required"
                    >
                      <b-form-input
                        id="billingUnit"
                        v-model="billingUnit"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="resourceType == 'Shared Resource' ? 'Available Working Hours' : 'Total Available Units'
                    "
                    label-for="totalUnit"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="resourceType == 'Shared Resource' ? 'Available Working Hours' : 'Total Available Units'"
                      rules="required"
                    >
                      <b-form-input
                        id="totalUnit"
                        v-model="totalUnit"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="resourceType == 'Shared Resource' ? 'Price per hour' : 'Price per unit'
                    "
                    label-for="unitPrice"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="resourceType == 'Shared Resource' ? 'Price per hour' : 'Price per unit'"
                      rules="required"
                    >
                      <b-input-group prepend="₹">
                        <b-form-input
                          id="unitPrice"
                          v-model="unitPrice"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                        /></b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>

          <!-- social link -->
          <tab-content
            :before-change="validationFormSocial"
            title="Create FAQs"
          >
            <validation-observer
              ref="socialRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Create FAQs
                  </h5>
                </b-col>
                <b-col ref="faqForm">
                  <faq-form />
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormFile, BInputGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import FaqForm from './components/FaqForm.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    vSelect,
    BFormTextarea,
    BFormFile,
    FaqForm,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      resourceName: '',
      resourceType: '',
      resourceDesc: '',
      resourceImages: '',
      resourceGroup: '',
      billingUnit: '',
      totalUnit: '',
      unitPrice: '',
      required,
      email,
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
