var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"subtitle":null,"title":null,"back-button-text":"Previous","color":"#7367F0","finish-button-text":"Submit","shape":"square"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"before-change":_vm.validationForm,"title":"Basic Information"}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Basic Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Resource Type","label-for":"resourceType"}},[_c('validation-provider',{attrs:{"name":"Resource Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"resourceType","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Shared Resource', 'Individual Resource', 'Limited Resource'],"label":"Resource Type","placeholder":"Resource Type"},model:{value:(_vm.resourceType),callback:function ($$v) {_vm.resourceType=$$v},expression:"resourceType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Resource Name","label-for":"resourceName"}},[_c('validation-provider',{attrs:{"name":"Resource Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"resourceName","state":errors.length > 0 ? false:null,"placeholder":"Meeting Room, Internet Credits, AWS Credits etc.","type":"text"},model:{value:(_vm.resourceName),callback:function ($$v) {_vm.resourceName=$$v},expression:"resourceName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Write max 50 word description","rows":"3"},model:{value:(_vm.resourceDesc),callback:function ($$v) {_vm.resourceDesc=$$v},expression:"resourceDesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Images","label-for":"images"}},[_c('validation-provider',{attrs:{"name":"Images","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"images","accept":"image/jpeg, image/png, image/gif"},model:{value:(_vm.resourceImages),callback:function ($$v) {_vm.resourceImages=$$v},expression:"resourceImages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"before-change":_vm.validationFormInfo,"title":"Resource Group"}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Resource Group ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Add or Select Group","label-for":"resourceGroup"}},[_c('validation-provider',{attrs:{"name":"Images","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"resourceGroup","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"Resource Group","placeholder":"Select from existing groups or enter new group name","taggable":"","push-tags":"","options":['Meeting Room','Conference Hall','Auditorium']},model:{value:(_vm.resourceGroup),callback:function ($$v) {_vm.resourceGroup=$$v},expression:"resourceGroup"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"before-change":_vm.validationFormAddress,"title":"Booking Information"}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Booking Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Resource Type","label-for":"resourceType"}},[_c('validation-provider',{attrs:{"name":"Resource Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"resourceType","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Shared Resource', 'Individual Resource', 'Limited Resource'],"label":"Resource Type","placeholder":"Resource Type"},model:{value:(_vm.resourceType),callback:function ($$v) {_vm.resourceType=$$v},expression:"resourceType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.resourceType == 'Shared Resource' ? 'Slot Duration' : 'Unit',"label-for":"billingUnit"}},[_c('validation-provider',{attrs:{"name":_vm.resourceType == 'Shared Resource' ? 'Slot Duration' : 'Unit',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"billingUnit","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.billingUnit),callback:function ($$v) {_vm.billingUnit=$$v},expression:"billingUnit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.resourceType == 'Shared Resource' ? 'Available Working Hours' : 'Total Available Units',"label-for":"totalUnit"}},[_c('validation-provider',{attrs:{"name":_vm.resourceType == 'Shared Resource' ? 'Available Working Hours' : 'Total Available Units',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"totalUnit","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.totalUnit),callback:function ($$v) {_vm.totalUnit=$$v},expression:"totalUnit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.resourceType == 'Shared Resource' ? 'Price per hour' : 'Price per unit',"label-for":"unitPrice"}},[_c('validation-provider',{attrs:{"name":_vm.resourceType == 'Shared Resource' ? 'Price per hour' : 'Price per unit',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"₹"}},[_c('b-form-input',{attrs:{"id":"unitPrice","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.unitPrice),callback:function ($$v) {_vm.unitPrice=$$v},expression:"unitPrice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"before-change":_vm.validationFormSocial,"title":"Create FAQs"}},[_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Create FAQs ")])]),_c('b-col',{ref:"faqForm"},[_c('faq-form')],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }